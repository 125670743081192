import * as React from "react"
import {graphql, Link, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import BlogPostPreviewList from 'contentcurator-gatsby-template/src/components/blog-post-preview-list'
import SearchBar from 'contentcurator-gatsby-template/src/components/search-bar'

import Layout from 'contentcurator-gatsby-template/src/containers/layout'
import Container from 'contentcurator-gatsby-template/src/components/container'

import * as styles_preview from 'contentcurator-gatsby-template/src/components/blog-post-preview-grid.module.css'

import * as styles from './index.module.css'
import {getPageUrl} from 'contentcurator-gatsby-template/src/lib/helpers'

import MetaSchemaSEO from 'contentcurator-gatsby-template/src/components/seo';

export const query = graphql`
  query {
    indexQuery: strapi{ siteSetting {
      id
      site_name
      default_seo_description
      featured_pages {
        id
        title
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
      }
    }}
    categoryPages: strapi {
      pages(sort: "title", limit: 100, publicationState: LIVE, where: {page_type: {name:"Categories"}}) {
        id
        slug
        page_type {
          id
          name
          slug_prefix
        }
        title
        title_short
      }
    }
    heroPages: strapi {
      pages(
        sort: "published_at:desc"
        limit: 1
        publicationState: LIVE
        where: { is_featured: "true" }
      ) {
        id
        is_featured
            slug
            page_type {
              name
              slug_prefix
            }
            site_section: page_type{
              name
              slug_prefix
            }
            title
            title_short
            primary_topic
            published_at
            parent {
              id
            }
            page_value
            main_image{
              url
              alternativeText
              caption
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 1000,
                    aspectRatio: 2,
                    transformOptions: {cropFocus:ENTROPY}
                  )
                }
              }
            }
      }
    }
    featuredTopPages: strapi {
      pages(
        sort: "published_at:desc"
        limit: 4
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 0
      ) {
        id
        is_featured
            slug
            page_type {
              name
              slug_prefix
            }
            site_section: page_type{
              name
              slug_prefix
            }
            title
            title_short
            primary_topic
            published_at
            parent {
              id
            }
            page_value
            main_image{
              url
              alternativeText
              caption
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 300,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY}
                  )
                }
              }
            }
      }
    }
    newsPages: strapi{ pages(
      sort: "created_at:desc"
      limit: 20
      publicationState: LIVE
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          published_at
          parent {
            id
          }
          main_image{
            url
            alternativeText
            caption
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 400,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      },
      relatedPages: strapi{ pages(
        sort: "created_at:desc"
        limit: 5
        publicationState: LIVE
        where: { is_featured: "true" }
        start: 4
        ) {
            id
            slug
            page_type {
              name
              slug_prefix
            }
            site_section: page_type{
              name
              slug_prefix
            }
            title
            excerpt
            published_at
            parent {
              id
              slug
            }
            main_image{
              url
              alternativeText
              caption
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 300,
                    aspectRatio: 1,
                    transformOptions: {cropFocus:ENTROPY}
                  )
                }
              }
            }
          }
        }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);
  const postNodes = data.newsPages.pages
  const featuredSitePages = data.featuredTopPages.pages
  const relatedPages = data.relatedPages.pages
  const categoryPages = data.categoryPages.pages
  

  return (
    <Layout>
      <MetaSchemaSEO
      />



      <section className={styles.promoBackground}>
        <div className={styles.promoContainer}>
            <div className={styles.dFlex}>
                <div className={styles.hero}>
                    <h1>Hello, how can we help you?</h1>

                    <SearchBar></SearchBar>

                    <p className={styles.heroSubtext}><span className="mr-2">Popular Topics:</span> <a className="text-white" href="/iphone/">iPhone</a>, <a className="text-white" href="/netflix/">Netflix</a>, <a className="text-white" href="/playstation/">PlayStation</a>, <a className="text-white" href="/samsung/">Samsung</a></p>
                </div>
            </div>
        </div>

        <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 323" enableBackground="new 0 0 1920 323" space="preserve">
            <polygon className={styles.svg1} fill="#ffffff" points="-0.5,322.5 -0.5,121.5 658.3,212.3 "></polygon>
            <polygon className={styles.svg2} fill="#ffffff" points="-2,323 1920,323 1920,-1 "></polygon>
        </svg>
      </section>
      <div className={styles.zIndex2}>
        <section className={styles.iconBlocks}>
            <div className={styles.noGutters}>
                <div className={styles.iconBlock}>
                <div className={styles.iconBlockItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon></svg>
                    <h4 className="mb-2">FAQ</h4>
                    <span>You have frequent questions, <br/> we have the answers</span>
                </div>
                </div>
                <div className={styles.iconBlock}>
                <div className={styles.iconBlockItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>

                    <h4 className="mb-2">Support Ticket</h4>
                    <span>Can't find what you are <br/> looking for - Submit a ticket</span>
                </div>
                </div>
                <div className={styles.iconBlock}>
                <div className={styles.iconBlockItem}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>

                    <h4 className="mb-2">Community</h4>
                    <span>Ask any questions from <br/> expert community members</span>
                </div>
                </div>
            </div>
          </section>
        </div>
      {featuredSitePages && 
      <div className={styles.featuredPages}>
        <ul className={styles_preview.grid_four}>
            {featuredSitePages.map(post => (
                <li key={post.id}>
                  <Link to={getPageUrl(post)}><GatsbyImage image={getImage(post.main_image.imageFile)} alt={post.title} /></Link>
                </li>
            ))}
        </ul>
      </div>
      }
      <Container>
        {categoryPages && categoryPages.length>0 &&
          <div className={styles.categoryPages}>
            <h2>Popular Categories of Errors Solutions</h2>
            <ul className={styles.categoryPageList}>
              {categoryPages &&
                categoryPages.map(node => (
                  <li key={`cat_${node.id}`}>
                    <Link to={getPageUrl(node)}>{node.title_short}</Link>
                  </li>
                ))}
          </ul>
        </div>
        }
      </Container>
        {relatedPages && (
          <>
            <BlogPostPreviewList
              title="Featured Error Solutions"
              nodes={relatedPages}
            />
          </>
        )}
      <Container>
        {postNodes && 
          <div className={styles.latestPages}>
            <h2>Recent Error Solutions</h2>
            <ul className={styles.listPages}>
                {postNodes.map(post => (
                    <li key={post.id}>
                      <Link to={getPageUrl(post)}>{post.title}</Link>
                    </li>
                ))}
            </ul>
          </div>
        }
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
        
      </Container>
    </Layout>
  )
}

export default IndexPage
